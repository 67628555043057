<template>
  <div>
    <!-- SKU弹出层 -->
    <el-dialog
      title="优惠信息"
      :visible.sync="dis"
      width="650px"
      @close="handleClose"
    >
      <div v-if="loading">
        <el-skeleton :rows="7" animated />
      </div>
      <div v-else>
        <div class="list" v-if="Coupon.length > 0">
          <div class="item" v-for="(item, index) in Coupon" :key="index">
            {{ item.text }}
          </div>
        </div>
        <el-empty description="暂无优惠信息" v-else></el-empty>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
      dis: true,
      Coupon: [], //列表
      loading: true,
    };
  },
  created() {
    this.$axios.get("/api/Coupon?goods_id=" + this.id).then((res) => {
      this.Coupon = res.data.data;
      this.loading = false;
    });
  },
  methods: {
    //   窗口关闭
    handleClose() {
      this.$emit("close", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  .item {
    width: 100%;
    height: 54px;
    line-height: 54px;
    border-top: 1px solid #e8e8e8;
    font-size: 14px;
    color: $text_color;
    &:last-child {
      border-bottom: 1px solid #e8e8e8;
    }
  }
}
</style>
