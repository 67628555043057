<template>
  <el-drawer
    title="管控记录"
    :visible="rowDdrawer"
    direction="rtl"
    :size="550"
    :before-close="handleClose"
  >
    <div class="steps">
      <el-steps
        :space="200"
        :active="skuV1Cope.actives"
        finish-status="success"
        align-center
      >
        <el-step title="待处理"></el-step>
        <el-step title="处理中"></el-step>
        <el-step title="已处理"></el-step>
      </el-steps>
      <div class="steps_radio">
        <div class="steps_radio_item">
          <el-radio v-model="skuV1Cope.treatment_phase" label="待沟通">待沟通</el-radio>
          <el-radio v-model="skuV1Cope.treatment_phase" label="待投诉">待投诉</el-radio>
        </div>
        <div class="steps_radio_item">
          <el-radio v-model="skuV1Cope.treatment_phase" label="沟通中">沟通中</el-radio>
          <el-radio v-model="skuV1Cope.treatment_phase" label="律师函">律师函</el-radio>
          <el-radio v-model="skuV1Cope.treatment_phase" label="买货中">买货中</el-radio>
          <el-radio v-model="skuV1Cope.treatment_phase" label="审核中">审核中</el-radio>
        </div>
        <div class="steps_radio_item">
          <el-radio v-model="skuV1Cope.treatment_phase" label="正价">正价</el-radio>
          <el-radio v-model="skuV1Cope.treatment_phase" label="删除">删除</el-radio>
          <el-radio v-model="skuV1Cope.treatment_phase" label="下架">下架</el-radio>
        </div>
      </div>
      <div class="steps_form">
        <el-form ref="skuV1" :model="skuV1Cope" label-width="90px">
          <el-form-item label="店铺数字ID">
            {{ sku.shop_id }}
          </el-form-item>
          <el-form-item label="商品链接">
            <div @click="asd(skuV1Cope.host, sku.goods_id)" style="cursor: pointer">
              {{ skuV1Cope.host | filter_format_goods_url(sku.goods_id) }}
            </div>
          </el-form-item>
          <el-form-item label="店铺名">
            {{ sku.shop_name }}
          </el-form-item>
          <el-form-item label="店铺文本ID">
            {{ sku.seller_nick }}
          </el-form-item>
          <el-form-item label="收录时间">
            {{ sku.source_time }}
          </el-form-item>
          <el-form-item label="定时任务">
            {{ sku.tb_schedule_name }}
          </el-form-item>
          <el-form-item label="核查时间">
            {{ sku.check_time }}
          </el-form-item>
          <el-form-item label="发货地">
            {{ sku.from }}
          </el-form-item>
          <el-form-item label="收录来源">
            <el-select
              v-model="skuV1Cope.source"
              placeholder="请选择收录来源"
              style="width: 100%"
            >
              <el-option
                v-for="(item, index) in sources"
                :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="管控类型">
            <el-select
              v-model="skuV1Cope.control_type"
              placeholder="请选择管控类型"
              style="width: 100%"
            >
              <el-option
                v-for="(item, index) in controlType"
                :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="优惠信息">
            <el-input type="textarea" v-model="skuV1Cope.discount"></el-input>
          </el-form-item>
          <el-form-item label="违规次数">
            <el-input v-model="skuV1Cope.illegal" type="number"></el-input>
          </el-form-item>
          <el-form-item label="影响程度">
            <el-select
              v-model="skuV1Cope.effect"
              placeholder="请选择影响程度"
              style="width: 100%"
            >
              <el-option label="S特殊" value="S特殊"></el-option>
              <el-option label="A重点" value="A重点"></el-option>
              <el-option label="B常规" value="B常规"></el-option>
              <el-option label="C其他" value="C其他"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="管控记录">
            <el-input type="textarea" v-model="skuV1Cope.control_record"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" :loading="loading" @click="onSkuV1Submit"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </el-drawer>
</template>

<script>
export default {
  props: ["rowDdrawer"],
  data() {
    return {
      loading: false,
      skuV1Cope: {},
      sku: {},
      sources: ["品牌方", "运营方", "双方"],
      controlType: ["控价", "打假", "禁售", "侵权", "监控"],
    };
  },
  created() {},
  // 监听数据变化
  watch: {
    "$store.state.tab.skuV1"() {
      this.skuV1Cope = this.$store.state.tab.skuV1;
    },
    "$store.state.tab.sku"() {
      this.sku = this.$store.state.tab.sku;
    },
  },
  methods: {
    onSkuV1Submit() {
      this.loading = true;
      this.$axios
        .put("/api/SpuDrawerUpdate", this.skuV1Cope)
        .then((res) => {
          this.loading = false;
          if (res.data.status == 0) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            //关闭窗口
            this.$emit("handleClose");
            //重新加载列表
            this.$emit("SkusV1");
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message({
            message: error,
            type: "error",
          });
        });
    },
    //   窗口关闭
    handleClose(done) {
      done();
      this.$emit("handleClose");
    },
    asd(value, goods_id) {
      if (!value) return "";
      switch (value) {
        case "item.taobao.com":
          window.open(`https://${value}/item.htm?id=${goods_id}`);
          break;
        case "2.taobao.com":
          window.open(`https://${value}/item.htm?id=${goods_id}`);
          break;
        case "h5.m.taobao.com":
          window.open(`https://${value}/item.htm?id=${goods_id}`);
          break;
        case "detail.tmall.com":
          window.open(`https://${value}/item.htm?id=${goods_id}`);
          break;
        case "chaoshi.detail.tmall.com":
          window.open(`https://${value}/item.htm?id=${goods_id}`);
          break;
        case "detail.m.tmall.com":
          window.open(`https://${value}/item.htm?id=${goods_id}`);
          break;
        case "detail.tmall.hk":
          window.open(`https://${value}/hk/item.htm?id=${goods_id}`);
          break;
        case "mobile.yangkeduo.com":
          window.open(`https://mobile.yangkeduo.com/goods.html?goods_id=${goods_id}`);
          break;
        case "app.yangkeduo.com":
          window.open(`https://mobile.yangkeduo.com/goods.html?goods_id=${goods_id}`);
          break;
        case "yangkeduo.com":
          window.open(`https://mobile.yangkeduo.com/goods.html?goods_id=${goods_id}`);
          break;
        case "youhui.pinduoduo.com":
          window.open(`https://mobile.yangkeduo.com/goods.html?goods_id=${goods_id}`);
          break;
        case "mobile.pinduoduo.com":
          window.open(`https://mobile.yangkeduo.com/goods.html?goods_id=${goods_id}`);
          break;
        case "pinduoduo.yangkeduo.com":
          window.open(`https://pinduoduo.yangkeduo.com/goods.html?goods_id=${goods_id}`);
          break;
        case "panduoduo.yangkeduo.com":
          window.open(`https://panduoduo.yangkeduo.com/goods.html?goods_id=${goods_id}`);
          break;
        case "item.jd.com":
          window.open(`https://${value}/${goods_id}.html`);
          break;
        case "item.m.jd.com":
          window.open(`https://${value}/product/${goods_id}.html`);
          break;
        case "npcitem.jd.hk":
          window.open(`https://${value}/${goods_id}.html`);
          break;
        case "pcitem.jd.hk":
          window.open(`https://${value}/${goods_id}.html`);
          break;
        case "detail.1688.com":
          window.open(`https://${value}/offer/${goods_id}.html`);
          break;
        case "detail.vip.com":
          window.open(`https://${value}/detail-${goods_id}.html`);
          break;
        case "www.vipglobal.hk":
          window.open(`https://${value}/detail-${goods_id}.html`);
          break;
        case "www.aliexpress.com":
          window.open(`https://${value}/item/${goods_id}.html`);
          break;
        default:
          return "";
      }
    },
  },
  /**
   * 过滤器
   */
  filters: {
    filter_format_goods_url: function (value, goods_id) {
      if (!value) return "";
      switch (value) {
        case "item.taobao.com":
          return `https://${value}/item.htm?id=${goods_id}`;
        case "2.taobao.com":
          return `https://${value}/item.htm?id=${goods_id}`;
        case "h5.m.taobao.com":
          return `https://${value}/item.htm?id=${goods_id}`;
        case "detail.tmall.com":
          return `https://${value}/item.htm?id=${goods_id}`;
        case "chaoshi.detail.tmall.com":
          return `https://${value}/item.htm?id=${goods_id}`;
        case "detail.m.tmall.com":
          return `https://${value}/item.htm?id=${goods_id}`;
        case "detail.tmall.hk":
          return `https://${value}/hk/item.htm?id=${goods_id}`;
        case "mobile.yangkeduo.com":
          return `https://mobile.yangkeduo.com/goods.html?goods_id=${goods_id}`;
        case "app.yangkeduo.com":
          return `https://mobile.yangkeduo.com/goods.html?goods_id=${goods_id}`;
        case "yangkeduo.com":
          return `https://mobile.yangkeduo.com/goods.html?goods_id=${goods_id}`;
        case "youhui.pinduoduo.com":
          return `https://mobile.yangkeduo.com/goods.html?goods_id=${goods_id}`;
        case "mobile.pinduoduo.com":
          return `https://mobile.yangkeduo.com/goods.html?goods_id=${goods_id}`;
        case "item.jd.com":
          return `https://${value}/${goods_id}.html`;
        case "item.m.jd.com":
          return `https://${value}/product/${goods_id}.html`;
        case "npcitem.jd.hk":
          return `https://${value}/${goods_id}.html`;
        case "pcitem.jd.hk":
          return `https://${value}/${goods_id}.html`;
        case "detail.1688.com":
          return `https://${value}/offer/${goods_id}.html`;
        case "detail.vip.com":
          return `https://${value}/detail-${goods_id}.html`;
        case "www.vipglobal.hk":
          return `https://${value}/detail-${goods_id}.html`;
        case "www.aliexpress.com":
          return `https://${value}/item/${goods_id}.html`;
        case "www.aliexpress.us":
          return `https://${value}/item/${goods_id}.html`;
        default:
          return "";
      }
      // value = value.toString()
      // return value.charAt(0).toUpperCase() + value.slice(1)
    },
  },
};
</script>

<style lang="scss" scoped>
.steps {
  display: flex;
  flex-direction: column;
  .steps_radio {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 30px;
    .steps_radio_item {
      display: flex;
      flex-direction: column;
      width: 166px;
      padding-left: 45px;
      box-sizing: border-box;
      .el-radio {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .steps_form {
    padding: 20px;
    margin-top: 20px;
  }
}
</style>
