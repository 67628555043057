<template>
  <div class="home">
    <!-- 头部搜索栏目 -->
    <div class="home_function">
      <el-input v-model="search.shop_name" placeholder="请输入店铺名称" size="small" class="home_model_input"></el-input>
      <el-input v-model="search.shop_id" placeholder="请输入店铺文本ID" size="small" class="home_model_input"></el-input>
      <el-date-picker v-model="search.source_time" type="date" class="home_model_input" format="yyyy-MM-dd"
        value-format="yyyy-MM-dd" size="small" placeholder="请输入收录日期"></el-date-picker>
      <el-input v-model="search.goods_id" placeholder="请输入商品ID" size="small" class="home_model_input"></el-input>
      <el-input v-model="search.spu_name" placeholder="请输入spu名称" size="small" class="home_model_input"></el-input>
      <el-input v-model="search.sku_description" placeholder="请输入SKU描述" size="small" class="home_model_input"></el-input>
      <el-input v-model="search.sku_name" placeholder="请输入SKU名称" size="small" class="home_model_input"></el-input>
      <el-input v-model="search.from" placeholder="请输入发货地" size="small" class="home_model_input"></el-input>
      <el-input v-model="search.illegal" placeholder="请输入违规次数" size="small" class="home_model_input"></el-input>
      <el-input v-model="search.control_record" placeholder="请输入管控记录" size="small" class="home_model_input"></el-input>
      <el-select v-model="search.effect" clearable size="small" placeholder="请选择影响程度" class="home_model_input">
        <el-option v-for="item in platform" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
      <el-select v-model="search.tb_schedule_id" clearable size="small" placeholder="请选择定时任务" class="home_model_input">
        <el-option v-for="item in task" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
      <el-select v-model="search.tb_shops_system_id" clearable size="small" placeholder="请选择店铺体系"
        class="home_model_input">
        <el-option v-for="item in this.filt.data" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
      <el-select v-model="search.tb_prices_level_id" clearable size="small" placeholder="请选择价格级别"
        class="home_model_input">
        <el-option v-for="item in this.level.data" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
      <el-date-picker v-model="value1" size="small" @change="checkTime" type="daterange" value-format="yyyy-MM-dd"
        style="margin-right: 40px" range-separator="至" start-placeholder="开始核查日期" end-placeholder="结束核查日期">
      </el-date-picker>
      <el-input v-model="search.keyword" placeholder="请输入关键词" size="small" class="home_model_input"></el-input>
      <el-button size="small" type="primary" class="prim" @click="load_SkusV1(true)" icon="el-icon-search"
        style="margin-left: 0">查询</el-button>
      <el-button size="small" class="prim1" @click="handleAdd" icon="el-icon-circle-plus">新建</el-button>
      <el-button size="small" class="prim1" @click="GoodsFile = true" icon="el-icon-upload2">导入</el-button>
      <div>
        <el-dropdown trigger="click" class="prim1" @command="handleExport" style="margin-left: 0">
          <el-button size="small" :loading="exportLoads">
            导出SPU
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="导出全部数据">导出全部数据</el-dropdown-item>
            <el-dropdown-item command="导出选中数据">导出选中数据</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown trigger="click" class="prim2" @command="handleDelete">
          <el-button size="small" :loading="exportLoading">
            删除SPU
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="删除所选SPU">删除所选SPU</el-dropdown-item>
            <el-dropdown-item command="删除全部SPU">删除全部SPU</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown trigger="click" class="prim2" @command="handleCheck">
          <el-button size="small" :loading="exportLoading">
            数据监控方案
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="设置全部数据">设置全部数据</el-dropdown-item>
            <el-dropdown-item command="设置选中数据">设置选中数据</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown trigger="click" class="prim2" @command="openFilt">
          <el-button size="small" :loading="exportLoading">
            店铺体系
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="设置全部数据">设置全部数据</el-dropdown-item>
            <el-dropdown-item command="设置选中数据">设置选中数据</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown trigger="click" class="prim2" @command="openLevel">
          <el-button size="small" :loading="exportLoading">
            价格级别
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="设置全部数据">设置全部数据</el-dropdown-item>
            <el-dropdown-item command="设置选中数据">设置选中数据</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown trigger="click" class="prim2" @command="clickControl">
          <el-button size="small">
            管控设置
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="设置选中数据">设置选中数据</el-dropdown-item>
            <el-dropdown-item command="设置全部数据">设置全部数据</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button size="small" class="prim1" @click="handleSkuPipei" :loading="skuPipei">sku自动匹配</el-button>
        <el-button size="small" class="prim1" @click="handlePricePipei" :loading="pricePipei">价格自动匹配</el-button>
      </div>
    </div>

    <!-- 表单 -->
    <div class="home_table">
      <el-table v-loading="loading" ref="multipleTable" @row-dblclick="handleTableRow" :data="tableData" height="100%"
        highlight-current-row :span-method="objectSpanMethod" style="width: 100%" @filter-change="handle_filter"
        :header-cell-style="{ background: '#fafafa', color: '#333333' }" @selection-change="handleSelectionChange">
        <el-table-column type="selection" column-key="choose" :filter-multiple="false" width="55"></el-table-column>
        <el-table-column prop="platform_name" label="平台" width="70" column-key="platform_name" align="center"
          :filter-multiple="false" :filters="[
            { text: '淘宝', value: '淘宝' },
            { text: '天猫', value: '天猫' },
            { text: '京东', value: '京东' },
            { text: '拼多多', value: '拼多多' },
            { text: '抖音', value: '抖音' },
            { text: '快手', value: '快手' },
            { text: '闲鱼', value: '闲鱼' },
            { text: '1688', value: '1688' },
            { text: '唯品会', value: '唯品会' },
            { text: '速卖通', value: '速卖通' },
          ]"></el-table-column>
        <el-table-column prop="goods_id" label="商品ID" width="100" align="center"></el-table-column>
        <el-table-column prop="source" label="收录来源" width="100" column-key="source" align="center" :filters="[
          { text: '品牌方', value: '品牌方' },
          { text: '运营方', value: '运营方' },
          { text: '双方', value: '双方' },
        ]" :filter-multiple="false"></el-table-column>
        <el-table-column prop="authorization_type" label="授权类型" align="center" min-width="100"
          column-key="authorization_type" :filters="[
            { text: '授权', value: '授权' },
            { text: '非授权', value: '非授权' },
            { text: '黑名单', value: '黑名单' },
          ]" :filter-multiple="false"></el-table-column>
        <el-table-column prop="control_type" label="管控类型" align="center" column-key="control_type" min-width="100"
          :filters="[
            { text: '控价', value: '控价' },
            { text: '打假', value: '打假' },
            { text: '禁售', value: '禁售' },
            { text: '侵权', value: '侵权' },
            { text: '监控', value: '监控' },
          ]" :filter-multiple="false"></el-table-column>
        <el-table-column prop="goods_pic_url" label="主图" width="100" align="center">
          <template slot-scope="scope">
            <div class="scope_box" style="cursor: pointer" @click="handleClick(scope.row.url)">
              <el-popover placement="right" width="350" trigger="hover">
                <el-image style="width: 100%; height: auto" :src="scope.row.goods_pic_url" fit="fill"></el-image>
                <el-image slot="reference" style="width: 48px; height: 48px" :src="scope.row.goods_pic_url"
                  fit="fill"></el-image>
              </el-popover>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="spu_name" label="SPU名称" min-width="190" align="center">
          <template slot-scope="scope">
            <div style="cursor: pointer" v-if="scope.row.spu_tag != null" @click="getSpuTag(scope.row.tb_goods_v1_id)">
              <el-tag type="" effect="dark" size="mini">SPU名称已变动</el-tag>
            </div>
            <el-tag type="" effect="dark" size="mini"
              v-if="change_label(scope.row.spu_name, scope.row.spu_name2)">改名</el-tag>
            {{ scope.row.spu_name }}
          </template>
        </el-table-column>
        <el-table-column prop="skus_pic_url" label="SKU图" width="100" align="center">
          <template slot-scope="scope">
            <div class="scope_box" style="cursor: pointer" @click="handleClick(scope.row.url)">
              <el-popover placement="right" width="350" trigger="hover">
                <el-image style="width: 100%; height: auto" :src="scope.row.skus_pic_url" fit="fill"></el-image>
                <el-image slot="reference" style="width: 48px; height: 48px" :src="scope.row.skus_pic_url"
                  fit="fill"></el-image>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="sku_description" label="SKU描述" min-width="190" align="center">
          <template slot-scope="scope">
            <el-tag type="success" effect="dark" size="mini" v-if="change_label(
              scope.row.sku_description,
              scope.row.sku_description2
            )
              ">改名</el-tag>

            <el-tag type="" effect="dark" size="mini" style="margin-left: 5px"
              v-if="scope.row.sku_link_status !== '在售'">{{ scope.row.sku_link_status }}</el-tag>
            {{ scope.row.sku_description }}
          </template>
        </el-table-column>

        <el-table-column prop="sku_name" label="SKU名称" min-width="190" align="center">
          <template slot-scope="scope">
            <div style="cursor: pointer" v-if="scope.row.sku_tag != null" @click="getSkuTag(scope.row.tb_skus_id)">
              <el-tag type="" effect="dark" size="mini">{{
                scope.row.sku_tag
              }}</el-tag>
            </div>
            <h1 v-if="scope.row.sku_name">{{ scope.row.sku_name }}</h1>
            <h1 v-if="scope.row.tb_skus_id != 0">
              <el-button @click.stop="handle_ProductsSkusCascader(scope.row)" type="text" size="small">设置</el-button>
            </h1>
          </template>
        </el-table-column>
        <el-table-column prop="normal_price" label="标价" align="center" width="120">
          <template slot-scope="scope">
            <div style="cursor: pointer; color: #409eff" @click="handleEchart(scope.row)">
              {{ scope.row.normal_price }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="real_price" label="到手价" min-width="90" align="center" sortable>
          <template slot-scope="scope">
            <div style="cursor: pointer; color: #409eff" @click="handleEchart(scope.row)">
              {{ scope.row.real_price }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="limit_price" label="指导价" min-width="90" align="center"></el-table-column>
        <el-table-column prop="tb_shops_system_name" label="店铺体系" min-width="150" align="center">
          <template slot-scope="scope">
            <div class="scope_box_column" style="cursor: pointer">
              <h1>{{ scope.row.tb_shops_system_name }}</h1>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="tb_prices_level_name" label="价格级别" min-width="150" align="center">
          <template slot-scope="scope">
            <div class="scope_box_column" style="cursor: pointer">
              <h1>{{ scope.row.tb_prices_level_name }}</h1>
              <h1 v-if="scope.row.tb_skus_id != 0"></h1>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="price_status" label="价格状态" min-width="90" column-key="price_status" align="center"
          :filters="[
            { text: '低价', value: '低价' },
            { text: '正价', value: '正价' },
            { text: '删除', value: '删除' },
          ]" :filter-multiple="false">
          <template slot-scope="scope">
            <div class="scope_box" @click="handleEchart(scope.row)" style="cursor: pointer; color: #409eff">
              <span class="scope_box_bule">{{ scope.row.price_status }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="link_status" label="链接状态" column-key="link_status" align="center" width="90" :filters="[
          { text: '在售', value: '在售' },
          { text: '下架', value: '下架' },
          { text: '删除', value: '删除' },
        ]" :filter-multiple="false">
          <template slot-scope="scope">
            <div class="scope_box" @click="handleEchart(scope.row)" style="cursor: pointer">
              <span v-if="scope.row.link_status == '在售'" class="scope_box_bule">{{ scope.row.link_status }}</span>
              <span v-else class="scope_box_no">{{
                scope.row.link_status
              }}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="schedule_name" label="活动状态" min-width="90" align="center"></el-table-column>
        <el-table-column prop="treatment_phase" label="处理阶段" min-width="90" column-key="treatment_phase" align="center"
          :filters="[
                { text: '待处理/待沟通', value: '待沟通' },
                { text: '待处理/待投诉', value: '待投诉' },
                { text: '处理中/沟通中', value: '沟通中' },
                { text: '处理中/律师函', value: '律师函' },
                { text: '处理中/买货中', value: '买货中' },
                { text: '处理中/审核中', value: '审核中' },
                { text: '已处理/删除', value: '删除' },
                { text: '已处理/下架', value: '下架' },
                { text: '已处理/正价', value: '正价' },
              ]" :filter-multiple="false">
          <template slot-scope="scope">
            {{ scope.row.treatment_phase | filter_format_price_status }}
          </template>
        </el-table-column>

        <el-table-column prop="skus_sales_volume" label="销量" align="center" min-width="120" sortable></el-table-column>
        <el-table-column prop="keyword" label="关键词" align="center" min-width="120" sortable></el-table-column>
      </el-table>
    </div>

    <div class="page">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="pageNum" :page-sizes="[50, 100, 200]" :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </div>
    <!-- SKU -->
    <common-sku v-if="skuShow" :id="tabId" @close="skuShow = false"></common-sku>
    <!-- 优惠信息 -->
    <common-dis v-if="disShow" :id="tabId" @close="disShow = false"></common-dis>
    <el-dialog title="上传商品" :visible.sync="GoodsFile" @close="GoodsFile = false" width="450px">
      <div class="GoodsFiles">
        <div class="GoodsFiles_Top_left">
          <!-- :on-success="upLoadImg"
            :on-error="errorImg" -->
          <el-upload class="upload-demo" ref="upload" :limit="1" :file-list="uploadFile" action="action" :multiple="false"
            :auto-upload="false" :http-request="allUpLoad" :on-change="handleChange" :on-remove="handleRemove">
            <el-button slot="trigger" type="primary">选择文件</el-button>
            <div slot="tip" class="el-upload__tip">
              只能上传xls/xlsx文件，且不超过3M
            </div>
          </el-upload>
        </div>
        <div class="GoodsFiles_Top_right">
          <p>
            上传格式模板：
            <a class="upLoad_Modules" :href="hrefs" target="_blank">下载模板</a>
          </p>
          <p>上传采用异步无需等待，结果会以邮件的形式通知到你</p>
          <p v-if="$store.state.tab.user != null">
            <b>{{ $store.state.tab.user.email }}</b>
          </p>
        </div>
        <div class="GoodsFiles_selete">
          <el-select v-model="upload.tb_schedule_id" style="width: 100%" placeholder="请选择数据监控方案">
            <el-option v-for="item in task" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-select v-model="upload.tb_prices_level_id" placeholder="请选择价格级别" style="width: 100%">
            <el-option v-for="item in level.data" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="GoodsFiles_footer">
          <el-button type="text" size="small" style="margin-right: 15px" @click="GoodsFile = false">取消</el-button>
          <el-button style="margin-left: 10px" size="small" type="success" @click="submitUpload"
            :loading="fileLoad">上传到服务器</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 数据监控方案弹框 -->
    <el-dialog title="设置数据监控方案" :visible.sync="dialogTask" width="400px" @close="taskClose">
      <!-- 选择定时任务 -->
      <el-select v-model="taskValue" style="width: 100%" placeholder="请选择定时任务" @change="handleTaskValue">
        <el-option v-for="item in task" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogTask = false">取 消</el-button>
        <el-button type="primary" @click="handleTask" :loading="taskLoading">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 关联SKU -->
    <el-dialog title="SKU设置" :visible.sync="skuDialog" width="410px">
      <div class="skuList" v-loading="skuLoad">
        <el-cascader :options="skuForm" filterable :filter-method="pinScreen" v-model="skuArray"></el-cascader>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="skuDialog = false">取 消</el-button>
        <el-button type="primary" @click="handleCascaderForm" :loading="skuLoading">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 侧边弹窗 -->

    <Skusv1-Drawer :rowDdrawer="rowDdrawer" v-on:handleClose="handleClose" v-on:SkusV1="load_SkusV1"></Skusv1-Drawer>

    <!-- 店铺体系弹框 -->
    <el-dialog title="店铺体系设置" :visible.sync="filt.show" width="400px">
      <el-form>
        <el-form-item>
          <el-select v-model="filt.value" placeholder="请选择店铺体系" style="width: 100%">
            <el-option v-for="item in filt.data" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="filt.show = false">取 消</el-button>
        <el-button type="primary" @click="handleTbSystemName">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 价格级别弹框 -->
    <el-dialog title="价格级别设置" :visible.sync="level.show" width="400px">
      <el-form>
        <el-form-item>
          <el-select v-model="level.value" placeholder="请选择价格级别" style="width: 100%">
            <el-option v-for="item in level.data" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="level.show = false">取 消</el-button>
        <el-button type="primary" @click="handlePriceLevel">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 管控设置弹框 -->
    <el-dialog title="管控设置" :visible.sync="setControl.show" width="400px">
      <el-form>
        <el-form-item>
          <el-select v-model="setControl.value" placeholder="请选择管控类型" style="width: 100%">
            <el-option label="控价" value="控价"></el-option>
            <el-option label="打假" value="打假"></el-option>
            <el-option label="禁售" value="禁售"></el-option>
            <el-option label="侵权" value="侵权"></el-option>
            <el-option label="监控" value="监控"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="setControl.show = false">取 消</el-button>
        <el-button type="primary" @click="spuUpdateControlType">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="SKU价格趋势" :visible.sync="dialogVisible" width="800px" :before-close="handleEchartClose">
      <span>SKU信息：SKU描述“{{ echarts_description }}”{{
        echarts_sku_name == null ? "" : "SKU名称“" + echarts_sku_name + "”"
      }}</span>
      <div style="width: 100%; height: 400px" id="echart" ref="echarts"></div>
    </el-dialog>
    <!-- spu名称变动 -->
    <el-dialog title="" :visible.sync="spuVisible" width="800px">
      <el-table :data="gridData" v-loading="spuLoading" border>
        <el-table-column property="check_time" label="变动的时间" align="center"></el-table-column>
        <el-table-column property="before_spu_name" label="变动前的SPU名称" align="center"></el-table-column>
        <el-table-column property="after_spu_name" label="变动后的SPU名称" align="center"></el-table-column>
      </el-table>
    </el-dialog>
    <!-- SPU名称已变动 -->
    <el-dialog title="" :visible.sync="skuVisible" width="900px">
      <el-table :data="gridskuData" v-loading="skuSpLoading" border>
        <el-table-column property="check_time" label="变动的时间" align="center"></el-table-column>
        <el-table-column property="before_sku_description" label="变动前的SKU描述" align="center"></el-table-column>
        <el-table-column property="after_sku_description" label="变动后的SKU描述" align="center"></el-table-column>
        <el-table-column property="before_sku_name" label="校正前的SKU名称" align="center"></el-table-column>
        <el-table-column property="after_sku_name" label="校正后的SKU名称" align="center"></el-table-column>
        <el-table-column property="corrected" label="是否已校正" align="center">
          <template slot-scope="scope">
            {{ scope.row.corrected == false ? "否" : "是" }}
          </template>
        </el-table-column>
        <el-table-column property="after_spu_name" label="链接" align="center"></el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="isDelete" width="550px" :close-on-click-modal="false"
      :before-close="isDeleteClose">
      <p style="margin-bottom: 20px">
        您要删除全部SPU，删除之后，数据将无法恢复，您确定要删除吗？
      </p>
      <div class="codes">
        <p>请输入验证码：</p>
        <el-input v-model="codeValue" placeholder="请输入验证码" class="codes_ipt"></el-input>
        <div class="codes_isde">{{ isCode }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isDelete = false">取 消</el-button>
        <el-button type="primary" @click="handleDeleteOpen()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from "echarts";
import commonSku from "../../components/CommonSku";
import commonDis from "../../components/CommonDis";
import Skusv1Drawer from "../../components/Skusv1Drawer";
import Aegis from "aegis-web-sdk";
import Cookies from 'js-cookie'
import excel from '../../export/libs/excel'
export default {
  components: {
    commonSku,
    commonDis,
    Skusv1Drawer,
  },
  data() {
    return {
      echartsLoading: false, //曲线图
      echarts_description: "", //sku描述
      echarts_sku_name: "", //sku名称
      isDelete: false, //打开是否删除弹窗
      codeValue: "",
      isCode: "1234", //删除弹窗验证码
      spuVisible: false, //spu变动
      spuLoading: false, //SPU表单加载
      gridData: [],

      skuVisible: false, //sku变动
      skuSpLoading: false, //Sku表单加载
      gridskuData: [],

      dialogVisible: false, //sku价格弹窗
      skuDialog: false, //关联SKU
      skuItem: null,
      skuForm: [], //SKU设置列表
      skuLoad: true, //SKU加载是否完成
      skuArray: [],
      skuLoading: false,
      exportLoading: false, //导出loading
      loadingText: '0%',
      // 店铺体系
      filt: {
        show: false,
        value: "",
        data: [],
      },
      filts: [],
      // 价格级别
      level: {
        id: [],
        show: false,
        value: "",
        data: [],
      },
      exportLoads: false,
      pricesLevel: [],
      // 管控设置
      setControl: {
        show: false,
        value: "",
      },
      skuLevel: {
        id: [],
      },
      skuPipei: false, //sku自动匹配loading
      pricePipei: false, //价格匹配loading
      // 更新Sku信息
      actives: null,
      spanArr: [], //一个空数组，用于存放每一行记录的合并数
      pos: 0, //spanArr索引
      GoodsFile: false, //打开导入文件窗口
      rowDdrawer: false, //打开行弹框
      // 请求头
      headers: {
        Authorization: "Bearer" + " " + localStorage.token,
      },
      uploadFile: [],
      // 上传文件参数
      upload: {
        tb_prices_level_id: "",
        tb_schedule_id: "",
      },
      fileLoad: false,
      hrefs:
        "https://datamonitorshop.oss-cn-hangzhou.aliyuncs.com/templet/%E5%95%86%E5%93%81%E4%B8%8A%E4%BC%A0%E6%A0%B7%E6%9C%AC.xls",
      // 影响程度
      platform: [
        {
          value: "S特殊",
          label: "S特殊",
        },
        {
          value: "A重点",
          label: "A重点",
        },
        {
          value: "B常规",
          label: "B常规",
        },
        {
          value: "C其他",
          label: "C其他",
        },
      ],
      // 搜索内容
      search: {
        keyword: "", //关键词
        tb_shops_system_id: "", //店铺体系
        tb_prices_level_id: "", //价格级别
        start_check_time: "", //开始时间
        end_check_time: "", //结束时间
        shop_name: "", //店铺名称
        shop_id: "", //店铺ID
        source_time: null, //收录日期
        goods_id: "", //商品ID
        spu_name: "", //SPU名称
        sku_description: "", //sku描述
        sku_name: "", //sku名称
        from: "", //发货地
        illegal: null, //违规次数
        control_record: "", //管控记录
        effect: "", //影响程度
        platform_name: "", //平台
        source: "", //收录来源
        authorization_type: "", //授权类型
        control_type: "", //管控类型
        price_status: "", //价格状态
        link_status: "", //链接状态
        treatment_phase: "", //处理状态
        last_tb_goods_v1_id: "",
        tb_schedule_id: null, //定时任务ID
      },
      value1: "",
      loading: true, //列表加载
      pageLoad: false, //分页显示
      tableData: [],
      tabId: null, //产品ID
      skuShow: false, //SKU窗口
      disShow: false, //优惠信息窗口
      vsbtn: false, //删除loading

      pageNum: 1, //目前第几页
      pageSize: 50, //默认每页50条
      total: 0, //总条数

      // 定时任务选择
      task: [],
      taskValue: "",
      taskLoading: false,
      tb_schedule_id: null,

      // 勾选内容
      Selection: [], //勾选的内容进过筛选的数据
      SelectionData: [], //勾选内容未进过筛选，用于判断是否全部选择
      seleLoading: false,
      dialogTask: false,
      choose: "", //此页数据和全部数据

      downExcel: [],//下载的内容
      downPage: 1,//当前第几页
      downErr: 0,//错误次数
    };
  },
  mounted() {
    let router = this.$router.history.current.query;
    if (JSON.stringify(router) != '{}') {
      window.localStorage.setItem('token', router.token)
      Cookies.set('token', router.token, { domain: 'datamonitor.shop' });
    } else {
      let token = Cookies.get('token');
      if (token) {
        window.localStorage.setItem('token', token)
      }
    }
    this.userInfo()
    // 商品列表
    this.load_SkusV1();
    // 定时任务
    this.load_Schedule();
    // 加载店铺体系
    this.handleFirst();
    // 价格级别
    this.handleThird();
    // 加载SKU设置内容
    this.handleProductsSkus();
  },
  created() {
    const aegis = new Aegis({
      id: "XaKVn5BzYaR3MRyjbO", // 上报 id
      uin: "xxx", // 用户唯一 ID（可选）
      reportApiSpeed: true, // 接口测速
      reportAssetSpeed: true, // 静态资源测速
      spa: true, // spa 应用页面跳转的时候开启 pv 计算
    });
    aegis.infoAll("上传");
    this.$store.commit("removeTabs", { name: "数据看板", router: "/" });

  },
  methods: {
    userInfo() {
      this.$axios.get("/api/v6/User").then((res) => {
        this.$store.commit("user", JSON.stringify(res.data.data));
        localStorage.setItem("user", JSON.stringify(res.data.data));
      });
    },
    // 删除关闭
    isDeleteClose(done) {
      done();
    },
    // 日期选择
    checkTime(e) {
      if (e == undefined || e == null) {
        this.search.start_check_time = "";
        this.search.end_check_time = "";
      } else {
        this.search.start_check_time = e[0];
        this.search.end_check_time = e[1];
      }

    },
    // 打开SPU名称变动表单
    getSpuTag(id) {
      this.spuLoading = true;
      this.spuVisible = true;
      this.$axios
        .get(`/api/v6/GoodsV1SpuNameUpdateCopy?tb_goods_v1_id=${id}`)
        .then((res) => {
          this.spuLoading = false;
          this.gridData = res.data.data;
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err.data.message,
          });
        });
    },
    getSkuTag(id) {
      this.skuSpLoading = true;
      this.skuVisible = true;
      this.$axios
        .get(`/api/v6/SkusV1DescriptionUpdateCopy?tb_skus_id=${id}`)
        .then((res) => {
          this.skuSpLoading = false;
          this.gridskuData = res.data.data;
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err.data.message,
          });
        });
    },
    // SKU价格趋势关闭
    handleEchartClose(done) {
      done();
      this.dialogVisible = false;
      // 销毁图表实例
      echarts.init(this.$refs.echarts).dispose();
    },
    // 打开SKU价格趋势，初始化
    handleEchart(row) {
      this.dialogVisible = true;
      this.echarts_description = row.sku_description;
      this.echarts_sku_name = row.sku_name;
      setTimeout(() => {
        this.initCharts(row);
      }, 300);
    },
    // 获取图表内容
    initCharts(row) {
      this.echartsLoading = true;
      this.$axios
        .get(`/api/v4/SkusV1Data?tb_goods_v1_id=${row.tb_goods_v1_id}`, {})
        .then((res) => {
          this.echartsLoading = false;
          if (res.data.status == 0) {
            let val = res.data.data;
            let listData = [];
            if (val.skusv1_price_copy.length > 0) {
              for (let i = 0; i < val.skusv1_price_copy.length; i++) {
                if (val.skusv1_price_copy[i].tb_skus_id == row.tb_skus_id) {
                  listData.push(val.skusv1_price_copy[i]);
                }
              }
              this.initEcharts(listData);
            }
          } else {
            this.$message({
              message: "加载错误",
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err.data.message,
          });
        });
    },
    // 初始化图表
    initEcharts(data) {
      let valData = []; //时间
      let normal = []; //标价
      let real = []; //到手价
      for (let i = data.length - 1; i >= 0; i--) {
        let time = this.setDate(data[i].check_time);
        valData.push(time);
        normal.push(data[i].normal_price);
        real.push(data[i].real_price);
      }
      var myChart = echarts.init(this.$refs.echarts);
      myChart.setOption({
        tooltip: { trigger: "axis" },
        legend: { data: ["标价", "到手价"], bottom: 10 },
        color: ["#5d90f8", "#5ad7a6"],
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: valData,
        },
        yAxis: { type: "value" },
        series: [
          {
            name: "标价",
            type: "line",
            smooth: true,
            data: normal,
            markLine: {
              silent: true,
              lineStyle: {
                color: "#333",
              },
              // 平均线
              // data: [
              // 	{
              // 		yAxis: 150
              // 	}
              // ]
            },
          },
          {
            name: "到手价",
            type: "line",
            smooth: true,
            data: real,
          },
        ],
      });
    },
    // 处理时间
    setDate(time) {
      let a = time.split(" ");
      let a1 = a[0].split("-");
      let a2 = a[1].split(":");
      return `${a1[1]}-${a1[2]} ${a2[0]}:${a2[1]}`;
    },
    // 文件列表移除文件时的钩子
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    // 文件状态改变时
    handleChange(file, fileList) {
      console.log(fileList);
      // this.$refs.upload.clearFiles();
      // this.uploadFile = [{name:file.name,url:file.url}];
    },
    // 选择此页或全部数据
    clickControl(command) {
      this.choose = command;
      if (command == "设置选中数据") {
        this.openSetControl();
      } else {
        this.setControl.value = "";
        this.setControl.show = true;
      }
    },
    // 判断名称改名提示
    change_label: function (value, value2) {
      if (!value2) {
        return false;
      }
      return value != value2;
    },
    allUpLoad(param) {
      console.log(param);
      const _file = param.file;
      const formData = new FormData();
      formData.append("file", _file);
      this.fileLoad = true;
      this.$axios
        .post1(
          `/api/v6/SpuUpload?tb_prices_level_id=${this.upload.tb_prices_level_id}&tb_schedule_id=${this.upload.tb_schedule_id}`,
          formData
        )
        .then((res) => {
          this.fileLoad = false;
          this.$alert(`${res.data.message}`, "提示", {
            confirmButtonText: "确定",
            callback: () => {
              this.GoodsFile = false;
              this.$refs.upload.clearFiles();
              this.load_SkusV1();
            },
          });
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err.data.message,
          });
        });
    },
    // SKU自定义搜索逻辑
    pinScreen(node, keyword) {
      if (
        !!~node.text.indexOf(keyword) ||
        !!~node.text.toUpperCase().indexOf(keyword.toUpperCase())
      ) {
        return true;
      }
      let key = keyword.split("，");
      let keyIdx = [];
      for (let i = 0; i < key.length; i++) {
        if (node.text.indexOf(key[i]) >= 0) {
          keyIdx.push(0);
        }
      }
      if (key.length == keyIdx.length) {
        return true;
      }
    },
    // 提交上传商品文件
    submitUpload() {
      console.log("1");
      this.$refs.upload.submit();
    },
    // 加载SKU设置内容
    handleProductsSkus() {
      this.$axios
        .get("/api/ProductsSkusCascader", {})
        .then((res) => {
          this.skuForm = res.data.data;
          this.skuLoad = false; //加载完成，API未加载完成前显示加载状态，主要防止API未加载成功，用户先打开SKU
        });
    },
    // 加载店铺体系
    handleFirst() {
      this.$axios
        .get("/api/v6/ShopsSystem", {})
        .then((res) => {
          if (res.data.status == 0) {
            let data = res.data.data;
            this.filt.data = [];
            for (let i in data) {
              this.filt.data.push({ label: data[i].name, value: data[i].id });
            }
          } else {
            this.$message({
              message: "加载错误",
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 加载价格级别
    handleThird() {
      this.$axios
        .get("/api/v6/PricesLevel", {})
        .then((res) => {
          if (res.data.status == 0) {
            let data = res.data.data;
            this.level.data = [];
            for (let i in data) {
              this.level.data.push({ label: data[i].name, value: data[i].id });
            }
          } else {
            this.$message({
              message: "加载错误",
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 打开侧边栏 or SKU弹框
    handleTableRow(row) {
      let sku = {};
      sku.tb_goods_v1_id = row.tb_goods_v1_id;
      sku.treatment_phase = row.treatment_phase;
      sku.discount = row.discount;
      sku.illegal = row.illegal;
      sku.effect = row.effect;
      sku.control_record = row.control_record;
      sku.host = row.host;
      sku.source = row.source;
      sku.control_type = row.control_type;

      if (row.treatment_phase == "待沟通" || row.treatment_phase == "待投诉") {
        sku.actives = 1;
      } else if (
        row.treatment_phase == "沟通中" ||
        row.treatment_phase == "律师函" ||
        row.treatment_phase == "买货中" ||
        row.treatment_phase == "审核中"
      ) {
        sku.actives = 2;
      } else if (
        row.treatment_phase == "删除" ||
        row.treatment_phase == "下架" ||
        row.treatment_phase == "正价"
      ) {
        sku.actives = 3;
      }
      this.$store.commit("skuV1", sku);
      this.$store.commit("sku", row);
      this.rowDdrawer = true;
    },
    // 侧边栏关闭
    handleClose() {
      this.rowDdrawer = false;
    },
    /**
     * 商品sku 关联 产品规则
     */
    // 关联SKU
    handle_ProductsSkusCascader(row) {
      this.skuLevel.id = [];
      this.skuLevel.id.push(row.tb_skus_id);
      this.skuArray = [];
      this.skuDialog = true;
      for (let i in this.skuForm) {
        if (this.skuForm[i].children.length > 0) {
          let sku = [];
          sku.push(this.skuForm[i].value);
          for (let k in this.skuForm[i].children) {
            if (row.sku_name == this.skuForm[i].children[k].label) {
              sku.push(this.skuForm[i].children[k].value);
              this.skuArray = sku;
              break;
            }
          }
        }
      }
    },
    // 保存SKU
    handleCascaderForm() {
      this.skuLoading = true;
      if (this.skuArray.length == 0) {
        this.skuLoading = false;
        this.$message({
          message: "请选择sku",
          type: "error",
        });
        return false;
      }
      this.$axios
        .put(
          `/api/v6/SkusV1UpdateSkuName?tb_skus_id=${this.skuLevel.id[0]}&tb_products_skus_id=${this.skuArray[1]}`
        )
        .then((res) => {
          this.skuLoading = false;
          if (res.data.status == 0) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.skuDialog = false;
            this.skuArray = [];
            this.load_SkusV1();
            this.handleProductsSkus();
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        })
        .catch((error) => {
          this.skuLoading = false;
          this.$message({
            message: error,
            type: "error",
          });
        });
    },
    // 定时任务关闭的回调
    taskClose() {
      this.taskValue = "";
      this.tb_schedule_id = null;
    },
    // 选择定时任务
    handleTaskValue(e) {
      this.tb_schedule_id = e;
    },
    // 点击打开数据监控方案弹框
    handleCheck(command) {
      this.choose = command;
      if (command == "设置选中数据" && this.Selection.length == 0) {
        this.$message({
          message: "请选择勾选需要设置的列表",
          type: "warning",
        });
        this.taskValue = "";
        return;
      }
      this.dialogTask = true;
    },
    // 价格自动匹配
    handlePricePipei() {
      if (this.Selection.length <= 0) {
        this.$message({
          type: "error",
          message: "请勾选需要匹配的列表",
        });
        return false;
      }
      this.pricePipei = true;
      this.$axios
        .post("/api/v3/PriceMatch", {
          tb_goods_v1_id: this.Selection,
        })
        .then((res) => {
          this.pricePipei = false;
          if (res.data.status == 0) {
            this.$message({
              type: "success",
              message: res.data.message,
            });
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        })
        .catch((error) => {
          this.pricePipei = false;
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    // sku自动匹配
    handleSkuPipei() {
      if (this.Selection.length <= 0) {
        this.$message({
          type: "error",
          message: "请勾选需要匹配的列表",
        });
        return false;
      }
      this.skuPipei = true;
      this.$axios
        .post("/api/v6/SkuMatch", {
          tb_goods_v1_id: this.Selection,
        })
        .then((res) => {
          this.skuPipei = false;
          if (res.data.status == 0) {
            this.$message({
              type: "success",
              message: res.data.message,
            });
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        })
        .catch((error) => {
          this.skuPipei = false;
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    // 选择定时任务的提交
    handleTask() {
      if (this.tb_schedule_id == null) {
        this.$message({
          type: "error",
          message: "请选择定时任务",
        });
        return;
      }
      let list = {
        skusV1IndexDTO: {},
        tb_goods_v1_list: this.Selection,
        tb_schedule_id: this.tb_schedule_id,
        choose: this.choose,
      }
      Object.assign(list.skusV1IndexDTO, this.search)
      this.taskLoading = true;
      this.$axios
        .post("/api/v6/SpuUpdateSchedule", list)
        .then((res) => {
          this.taskLoading = false;
          this.dialogTask = false;
          if (res.data.status == 0) {
            this.$message({
              type: "success",
              message: res.data.message,
            });
            this.taskValue = "";
            this.load_SkusV1();
          }
        })
        .catch((error) => {
          this.taskLoading = false;
          this.taskValue = "";
          this.dialogTask = false;
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    // 列表勾选
    handleSelectionChange(val) {
      this.SelectionData = val;
      if (val.length == 0) return (this.Selection = val);
      var arr = []; //为多个元素时，ID会有重复，需要过滤重复的ID
      var arrSku = []; //多SKU设置
      var tb_skus_id = []; //记录tb_skus_id，使用更新价格级别SkusV1UpdatePricesLevel API使用到
      for (var i = 0; i < val.length; i++) {
        if (arr.indexOf(val[i].tb_goods_v1_id) == -1) {
          arr.push(val[i].tb_goods_v1_id);
          tb_skus_id.push(val[i].tb_skus_id);
        }
      }
      for (var k = 0; k < val.length; k++) {
        // 设置SKU的元素
        if (arrSku.indexOf(val[k].tb_skus_id) == -1) {
          arrSku.push(val[k].tb_skus_id);
        }
      }
      this.Selection = arr;
      console.log(this.Selection);
      this.level.id = tb_skus_id;
      this.skuLevel.id = arrSku;
    },
    // 批量删除
    handleDeleteOpen() {
      if (this.codeValue != this.isCode) {
        this.$message.error("验证码错误！");
        return;
      }
      this.isDelete = false;
      this.codeValue = "";
      let datas = {
        skusV1IndexDTO: {},
        tb_goods_v1_list: this.Selection,
        choose: "删除全部SPU",
      };
      Object.assign(datas.skusV1IndexDTO, this.search);
      this.seleLoading = true;
      this.$axios
        .post("/api/v6/SpuDelete", datas)
        .then((res) => {
          this.seleLoading = false;
          if (res.data.status == 0) {
            this.$message({
              type: "success",
              message: res.data.message,
            });
            this.load_SkusV1();
          }
        })
        .catch(() => {
          this.seleLoading = false;
        });
    },
    // 单个删除
    handleDelete(command) {
      if (this.Selection.length == 0 && command == "删除所选SPU") {
        this.$message({
          message: "请先勾选列表内容",
          type: "warning",
        });
        return false;
      }
      this.choose = command;
      let title =
        command == "删除所选SPU"
          ? "是否确认删除勾选内容"
          : "是否确认删除全部内容";
      let datas = {
        skusV1IndexDTO: {},
        tb_goods_v1_list: this.Selection,
        choose: this.choose,
      };
      Object.assign(datas.skusV1IndexDTO, this.search);
      if (command == "删除所选SPU") {
        this.$confirm(title, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.seleLoading = true;
          this.$axios
            .post("/api/v6/SpuDelete", datas)
            .then((res) => {
              this.seleLoading = false;
              if (res.data.status == 0) {
                this.$message({
                  type: "success",
                  message: res.data.message,
                });
                this.load_SkusV1();
              }
            })
            .catch(() => {
              this.seleLoading = false;
            });
        });
      } else {
        this.isCode = Math.floor(Math.random() * (9999 - 1000)) + 1000;
        this.isDelete = true;
      }
    },
    // 获取数据；
    load_Schedule() {
      this.$axios.get("/api/v6/Schedule", {}).then((res) => {
        if (res.data.status == 0) {
          for (let i in res.data.data) {
            this.task.push({
              value: res.data.data[i].id,
              label: res.data.data[i].name,
            });
          }
        }
      });
    },
    // 筛选
    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
    },
    /**
     * 过滤筛选
     */
    handle_filter(filters) {
      if (filters.choose) {
        this.choose = filters.choose[0];
        for (var i = 0; i < this.tableData.length; i++) {
          this.$refs.multipleTable.toggleRowSelection(this.tableData[i]);
        }
        return;
      } else if (filters.platform_name) {
        this.search.platform_name = filters.platform_name[0];
      } else if (filters.source) {
        this.search.source = filters.source[0];
      } else if (filters.authorization_type) {
        this.search.authorization_type = filters.authorization_type[0];
      } else if (filters.control_type) {
        this.search.control_type = filters.control_type[0];
      } else if (filters.price_status) {
        this.search.price_status = filters.price_status[0];
      } else if (filters.link_status) {
        this.search.link_status = filters.link_status[0];
      } else if (filters.treatment_phase) {
        this.search.treatment_phase = filters.treatment_phase[0];
      }
      this.load_SkusV1(true);
    },
    /**
     * 加载 sku v1 数据
     */
    load_SkusV1(ser) {
      this.loading = true;
      let data = this.search;
      data.platform_name =
        data.platform_name == undefined ? "" : data.platform_name;
      data.source = data.source == undefined ? "" : data.source;
      data.authorization_type =
        data.authorization_type == undefined ? "" : data.authorization_type;
      data.control_type =
        data.control_type == undefined ? "" : data.control_type;
      data.price_status =
        data.price_status == undefined ? "" : data.price_status;
      data.link_status = data.link_status == undefined ? "" : data.link_status;
      data.treatment_phase =
        data.treatment_phase == undefined ? "" : data.treatment_phase;
      this.pageNum = ser ? 1 : this.pageNum;
      data.pageNum = this.pageNum;
      data.pageSize = this.pageSize;
      this.$axios
        .post("/api/v7/SkusV1", data)
        .then((res) => {
          if (res.data.status == 0) {
            this.total = res.data.bag.totalRows;
            this.tableData = res.data.data;
            this.spanArr = [];
            for (var i = 0; i < res.data.data.length; i++) {
              if (i === 0) {
                this.spanArr.push(1);
                this.pos = 0;
              } else {
                if (
                  res.data.data[i].tb_goods_v1_id ===
                  res.data.data[i - 1].tb_goods_v1_id
                ) {
                  this.spanArr[this.pos] += 1;
                  this.spanArr.push(0);
                } else {
                  this.spanArr.push(1);
                  this.pos = i;
                }
              }
            }
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
          this.loading = false;
          this.pageLoad = true;
        })
        .catch((err) => {
          this.loading = false;
          this.$message({
            message: err,
            type: "error",
          });
        });
    },
    // 全部下载 - 前端下载模式
    handleExportExcel() {
      this.downExcel = [];
      this.downPage = 1;
      let total = this.total;
      let num = Math.ceil(total / 1000);
      // const loading = this.$loading({
      //   lock: true,
      //   text: '正在下载' + this.loadingText,
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)'
      // });
      this.handleExcelset(num, 1000);
    },
    async handleExcelset(num) {
      console.log(num)
      console.log(this.downPage)
      let page = this.downPage;
      this.loadingText = Math.ceil((page / num) * 100) + '%'
      console.log(this.loadingText)
      if (page <= num) {
        this.getTableData(num)
      } else {
        await this.set_shopUrl();
        this.exportLoads = false;
        const params = {
          title: ['平台名称', '商品ID', '收录来源', '授权类型(授权/非授权)', '管控类型', 'spu名称', 'sku 描述', 'sku名称', '正常价格(标价)', '到手价', '限制价格（指导价）', '店铺体系',
            '价格级别', '价格状态', '销售状态', 'sku销售状态', '活动状态', '处理阶段', '销量', '店铺数字ID', '商品链接', '店铺名称', '店铺文本ID', '收录日期',
            '核查时间', '发货地区', '优惠信息', '违规次数', '影响程度', '管控记录', '授权公司', '店铺信息'],
          key: ['platform_name', 'goods_id', 'source', 'authorization_type', 'control_type', 'spu_name', 'sku_description', 'sku_name', 'normal_price', 'real_price', 'limit_price', 'tb_shops_system_name',
            'tb_prices_level_name', 'price_status', 'link_status', 'sku_link_status', 'schedule_name', 'treatment_phase', 'skus_sales_volume', 'shop_id', 'shop_urls', 'shop_name', 'seller_nick', 'source_time',
            'sku_check_time', 'from', 'discount', 'illegal', 'effect', 'control_record', 'company', 'information'],
          data: this.downExcel, // 数据源
          autoWidth: false, //autoWidth等于true，那么列的宽度会适应那一列最长的值
          filename: '商品导出'
        }
        excel.exportArrayToExcel(params)
      }
    },
    set_shopUrl() {
      return new Promise((resove) => {
        let idx = this.downExcel.length;
        for (let i = 0; i < this.downExcel.length; i++) {
          this.downExcel[i].shop_urls = this.filter_format_goods_url(this.downExcel[i].host, this.downExcel[i].goods_id);
          if (i + 1 >= idx) {
            resove(1)
          }
        }
      })
    },
    filter_format_goods_url(value, goods_id) {
      if (!value) return "";
      switch (value) {
        case "item.taobao.com":
          return `https://${value}/item.htm?id=${goods_id}`;
        case "2.taobao.com":
          return `https://${value}/item.htm?id=${goods_id}`;
        case "h5.m.taobao.com":
          return `https://${value}/item.htm?id=${goods_id}`;
        case "detail.tmall.com":
          return `https://${value}/item.htm?id=${goods_id}`;
        case "chaoshi.detail.tmall.com":
          return `https://${value}/item.htm?id=${goods_id}`;
        case "detail.m.tmall.com":
          return `https://${value}/item.htm?id=${goods_id}`;
        case "detail.tmall.hk":
          return `https://${value}/hk/item.htm?id=${goods_id}`;
        case "mobile.yangkeduo.com":
          return `https://mobile.yangkeduo.com/goods.html?goods_id=${goods_id}`;
        case "app.yangkeduo.com":
          return `https://mobile.yangkeduo.com/goods.html?goods_id=${goods_id}`;
        case "yangkeduo.com":
          return `https://mobile.yangkeduo.com/goods.html?goods_id=${goods_id}`;
        case "youhui.pinduoduo.com":
          return `https://mobile.yangkeduo.com/goods.html?goods_id=${goods_id}`;
        case "mobile.pinduoduo.com":
          return `https://mobile.yangkeduo.com/goods.html?goods_id=${goods_id}`;
        case "item.jd.com":
          return `https://${value}/${goods_id}.html`;
        case "item.m.jd.com":
          return `https://${value}/product/${goods_id}.html`;
        case "npcitem.jd.hk":
          return `https://${value}/${goods_id}.html`;
        case "pcitem.jd.hk":
          return `https://${value}/${goods_id}.html`;
        case "detail.1688.com":
          return `https://${value}/offer/${goods_id}.html`;
        case "detail.vip.com":
          return `https://${value}/detail-${goods_id}.html`;
        case "www.vipglobal.hk":
          return `https://${value}/detail-${goods_id}.html`;
        case "www.aliexpress.com":
          return `https://${value}/item/${goods_id}.html`;
        case "www.aliexpress.us":
          return `https://${value}/item/${goods_id}.html`;
        default:
          return "";
      }
    },
    /**
     * 获取下载数据；
     * @param {*} num 总页数
     * @param {*} size 每页多少条
     */
    getTableData(num) {
      let data = this.search;
      data.platform_name =
        data.platform_name == undefined ? "" : data.platform_name;
      data.source = data.source == undefined ? "" : data.source;
      data.authorization_type =
        data.authorization_type == undefined ? "" : data.authorization_type;
      data.control_type =
        data.control_type == undefined ? "" : data.control_type;
      data.price_status =
        data.price_status == undefined ? "" : data.price_status;
      data.link_status = data.link_status == undefined ? "" : data.link_status;
      data.treatment_phase =
        data.treatment_phase == undefined ? "" : data.treatment_phase;
      data.pageNum = this.downPage;
      data.pageSize = 100;
      this.$axios
        .post("/api/v7/SkusV1", data)
        .then((res) => {
          if (res.data.status == 0) {
            if (this.downExcel.length <= 0) {
              this.downExcel = res.data.data;
            } else {
              this.downExcel = this.downExcel.concat(res.data.data)
            }
            this.downPage++;
            this.handleExcelset(num)
          } else {
            if (this.downErr < 3) {
              this.downErr += 1;
              this.getTableData(num)
              return;
            }
            this.$message({
              message: '导出失败',
              type: "error",
            });
            this.exportLoads = false;
          }
        })
        .catch(() => {
          if (this.downErr < 3) {
            this.downErr += 1;
            this.getTableData(num)
            return;
          }
          this.$message({
            message: '导出失败',
            type: "error",
          });
          this.exportLoads = false;
        });
    },
    // 每页显示条数
    handleSizeChange(val) {
      if (val == this.pageSize) return;
      this.pageSize = val;
      this.load_SkusV1();
    },
    // 当前页面
    handleCurrentChange(val) {
      if (val == this.pageNum) return;
      this.search.last_tb_goods_v1_id =
        val == 1
          ? ""
          : this.tableData[this.tableData.length - 1].tb_goods_v1_id;
      this.pageNum = val;
      this.load_SkusV1();
    },
    // sku
    handleSku(index, row) {
      console.log(index);
      console.log(row);
      this.tabId = parseInt(row.id);
      this.skuShow = true;
    },
    // 优惠信息
    handleDis(index, row) {
      console.log(index);
      console.log(row);
      this.tabId = row.id;
      this.disShow = true;
    },
    // 监控返回字段
    compValue(index, row, value) {
      return {
        index,
        row,
        value,
      };
    },
    // 添加产品
    handleAdd() {
      this.$store.commit("tabs", { name: "添加宝贝", router: "/add" });
      this.$router.push({ name: "add" });
    },
    // 调价跟踪
    handleAfter(row) {
      this.$store.commit("tabs", { name: "调价跟踪", router: "/after" });
      this.$router.push({ name: "after", params: { id: row.id } });
    },
    // 商品url跳转
    handleClick(url) {
      window.open(url);
    },
    // 掌柜名称跳转链接
    handleShopUrl(url) {
      if (url == null) return;
      window.open(url);
    },
    // 导入
    GoodsExport() {
      this.$axios
        .post("/api/GoodsExport", {})
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 删除按钮 确定
    handlePopover(id) {
      this.vsbtn = true;
      this.$axios
        .delete_params("/api/SkusV1", { id })
        .then((res) => {
          this.vsbtn = false;
          if (res.data.status == 0) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.$refs[`popover-${id}`].doClose();
            this.load_SkusV1();
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.vsbtn = false;
          console.log(err);
        });
    },
    // 商品导出
    handleExport(command) {
      if (command == '导出全部数据') {
        this.downExcel = [];
        this.downPage = 1;
        this.downErr = 0;
        let total = this.total;
        let num = Math.ceil(total / 100);
        this.exportLoads = true;
        this.handleExcelset(num, 100);
        return
      }
      
      if (this.Selection.length == 0 && command == "导出选中数据") {
        this.$message({
          message: "请先勾选列表内容",
          type: "warning",
        });
        return false;
      }

      let data = {};
      data.skusV1IndexDTO = this.search;
      data.skusV1IndexDTO.pageNum = this.pageNum;
      data.skusV1IndexDTO.pageSize = this.pageSize;
      data.choose = command;
      data.tb_goods_v1_list = this.Selection;
      this.exportLoading = true;
      this.$axios
        .postData("/api/v6/SpuExport", data)
        .then((res) => {
          this.exportLoading = false;
          let name = res.headers["content-disposition"];
          let names = name.split("''");
          let blob = new Blob([res.data], { type: "application/vnd.ms-excel" }); //接收文件流文件
          const fileName = decodeURI(names[1]);
          const elink = document.createElement("a");
          elink.download = fileName;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        })
        .catch((err) => {
          this.exportLoading = false;
          this.$message({
            message: err,
            type: "error",
          });
        });
    },

    // 设置合并行
    objectSpanMethod({ rowIndex, columnIndex }) {
      if (columnIndex <= 7 || columnIndex >= 14) {
        const _row = this.spanArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },

    // 打开店铺体系
    openFilt(command) {
      if (this.Selection.length == 0 && command == "设置选中数据") {
        this.$message({
          message: "请先勾选列表内容",
          type: "warning",
        });
        return false;
      }
      this.choose = command;
      this.filt.value = "";
      this.filt.show = true;
    },
    // 更新店铺体系
    handleTbSystemName() {
      if (typeof this.filt.value !== "number" || isNaN(this.filt.value)) {
        this.filt.show = false;
        this.filt.value = "";
        return false;
      }
      let list = {
        skusV1IndexDTO: {},
        tb_goods_v1_list: this.Selection,
        tb_shops_system_id: this.filt.value,
        choose: this.choose,
      }
      Object.assign(list.skusV1IndexDTO, this.search)
      this.$axios
        .post(`/api/v6/SpuUpdateShopsSystem`, list)
        .then((res) => {
          if (res.data.status == 0) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.filt.show = false;
            this.filt.value = "";
            this.load_SkusV1();
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        })
        .catch((error) => {
          this.$message({
            message: error,
            type: "error",
          });
        });
    },
    // 打开管控设置
    openSetControl() {
      if (this.Selection.length == 0) {
        this.$message({
          message: "请先勾选列表内容",
          type: "warning",
        });
        return false;
      }
      this.setControl.value = "";
      this.setControl.show = true;
    },
    // 更新管控设置
    spuUpdateControlType() {
      if (this.setControl.value == "") {
        this.$message({
          message: "请选择管控类型",
          type: "warning",
        });
        return false;
      }
      let list = {
        skusV1IndexDTO: {},
        tb_goods_v1_list: this.Selection,
        control_type: this.setControl.value,
        choose: this.choose,
      }
      Object.assign(list.skusV1IndexDTO, this.search)
      this.$axios
        .post(`/api/v6/SpuUpdateControlType`, list)
        .then((res) => {
          if (res.data.status == 0) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.setControl.show = false;
            this.setControl.value = "";
            this.load_SkusV1();
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        })
        .catch((error) => {
          this.$message({
            message: error,
            type: "error",
          });
        });
    },
    // 打开价格级别
    openLevel(command) {
      if (this.Selection.length == 0 && command == "设置选中数据") {
        this.$message({
          message: "请先勾选列表内容",
          type: "warning",
        });
        return false;
      }
      this.choose = command;
      this.level.value = "";
      this.level.show = true;
    },
    openSku() {
      if (this.Selection.length == 0) {
        this.$message({
          message: "请先勾选列表内容",
          type: "warning",
        });
        return false;
      }
      this.skuDialog = true;
    },
    // 更新价格级别
    handlePriceLevel() {
      if (typeof this.level.value !== "number" || isNaN(this.level.value)) {
        this.level.show = false;
        this.level.value = "";
        return false;
      }
      let list = {
        skusV1IndexDTO: {},
        tb_goods_v1_list: this.Selection,
        tb_prices_level_id: this.level.value,
        choose: this.choose,
      };
      Object.assign(list.skusV1IndexDTO, this.search)
      this.$axios
        .post(`/api/v6/SpuUpdatePricesLevel`, list)
        .then((res) => {
          if (res.data.status == 0) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.level.show = false;
            this.level.value = "";
            this.load_SkusV1();
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        })
        .catch((error) => {
          this.$message({
            message: error,
            type: "error",
          });
        });
    },
  },
  /**
   * 过滤器
   */
  filters: {
    filter_format_price_status: function (value) {
      if (!value) return "";
      switch (value) {
        case "待沟通":
          return `待处理/待沟通`;
        case "待投诉":
          return `待处理/待投诉`;
        case "沟通中":
          return `处理中/沟通中`;
        case "律师函":
          return `处理中/律师函`;
        case "买货中":
          return `处理中/买货中`;
        case "审核中":
          return `处理中/审核中`;
        case "删除":
          return `已处理/删除`;
        case "下架":
          return `已处理/下架`;
        case "正价":
          return `已处理/正价`;
        default:
          return "";
      }
      // value = value.toString()
      // return value.charAt(0).toUpperCase() + value.slice(1)
    },
  },
};
</script>
<style>
.el-table__body tr.current-row>td.el-table__cell {
  background-color: #d4e6f9 !important;
}
</style>

<style lang="scss" scoped>
.home {
  width: 100%;
  min-width: 1392px;
  // min-height: 500px;
  height: 100%;
  background-color: #ffffff;
  padding: 24px 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1400px) {
    min-width: 1110px;
    min-height: 800px;
  }

  .home_function {
    width: 100%;
    margin-bottom: 24px;

    .home_model_input {
      width: 200px;
      margin-right: 40px;
      margin-bottom: 10px;
    }

    .prim {
      background-color: #3978f7;
      margin-left: 40px;
      margin-bottom: 10px;
      width: 80px;

      @media screen and (max-width: 1400px) {
        margin-left: 25px;
      }
    }

    .prim1 {
      margin-left: 40px;
      margin-bottom: 10px;
      min-width: 100px;

      @media screen and (max-width: 1400px) {
        margin-left: 25px;
      }
    }

    .prim2 {
      margin-left: 40px;

      // width: 120px;
      @media screen and (max-width: 1400px) {
        margin-left: 25px;
      }
    }
  }

  .home_table {
    flex: 1;
  }
}

.blue {
  color: #3978f7;

  &:hover {
    text-decoration: underline;
  }
}

.scope_box_column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.scope_box {
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    margin-left: 8px;
    color: #3978f7;

    &:hover {
      text-decoration: underline;
    }
  }

  .scope_box_bule {
    color: #3978f7;
  }

  .scope_box_no {
    color: #f42f2f;
  }
}

.scope_price {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.scope_operat {
  display: flex;
  // flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  // justify-content: space-between;
  justify-content: center;

  .oper_box {
    padding: 2px 0;
    color: #3978f7;
    font-size: 14px;
    cursor: pointer;
  }
}

.el-dropdown-link {
  color: #3978f7;
  font-size: 14px;
  cursor: pointer;
}

.page {
  padding-top: 32px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .page_left {
    flex: 1;

    .prim {
      background-color: #3978f7;
      margin-left: 20px;
      width: 120px;

      @media screen and (max-width: 1250px) {
        margin-right: 130px;
        margin-left: 0 !important;
        margin-top: 10px;
      }
    }
  }
}

.GoodsFiles {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 240px;

  .GoodsFiles_Top_left {
    margin-top: 10px;
    max-width: 198px;
  }

  .GoodsFiles_Top_right {
    width: 200px;

    p {
      font-size: 14px;
      color: #333333;
      line-height: 1.8;
    }
  }

  .GoodsFiles_selete {
    flex: 1;
    display: flex;
    min-width: 300px;
    margin: 15px 0;
    width: 100%;
    height: 45px;

    .el-select {
      margin: 0 10px;
    }
  }

  .GoodsFiles_footer {
    flex: 1;
    display: flex;
    min-width: 300px;
    width: 100%;
    justify-content: flex-end;
    padding: 0 10px;
  }
}

.el-upload__tip {
  text-align: center;
}

.steps {
  display: flex;
  flex-direction: column;

  .steps_radio {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 30px;

    .steps_radio_item {
      display: flex;
      flex-direction: column;
      width: 166px;
      padding-left: 45px;
      box-sizing: border-box;

      .el-radio {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .steps_form {
    padding: 20px;
    margin-top: 20px;
  }
}

.el-upload__tip {
  text-align: left !important;
}

.upLoad_Modules {
  color: #3978f7;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.skuList {
  padding-bottom: 80px;

  .el-cascader {
    width: 100%;
  }
}

.upload-demo {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.codes {
  width: 100%;
  display: flex;
  align-items: center;

  .codes_ipt {
    width: 200px;
  }

  .codes_isde {
    width: 150px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    margin-left: 20px;
    background: #eee;
    letter-spacing: 11px;
  }
}
</style>
