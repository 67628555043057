<template>
  <div>
    <!-- SKU弹出层 -->
    <el-dialog title="SKU分析" :visible.sync="sku" @close="handleClose" width="750px">
      <el-table
        v-loading="loading"
        :data="gridData"
        :header-cell-style="{ background: '#fafafa', color: '#333333' }"
      >
        <el-table-column
          property="date"
          label="SKU"
          min-width="280"
          align="center"
        >
          <template slot-scope="scope">
            <div class="scope_box">
              <el-popover placement="right" width="350" trigger="hover">
              <el-image
                style="width: 100%; height: auto"
                :src="scope.row.pic_url"
                fit="fill"
              ></el-image>
              <el-image
                slot="reference"
                style="width: 48px; height: 48px"
                :src="scope.row.pic_url"
                fit="fill"
              ></el-image>
            </el-popover>
              <span>{{ scope.row.title }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          property="price"
          label="标价"
          min-width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          property="stock"
          label="库存"
          min-width="100"
        ></el-table-column>
        <el-table-column
          property="rel_price"
          label="到手价"
          min-width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          property="price_range"
          label="指导价"
          min-width="100"
          align="center"
        >
          <template slot-scope="scope">
            <el-popover
              :ref="`popover-${scope.$index}`"
              placement="bottom"
              width="260"
              :offset="-70"
            >
              <div class="scope_ref">
                <p>改价</p>
                <el-input
                  v-model="input"
                  placeholder="请输入"
                  size="medium"
                ></el-input>
                <div class="scope_btn">
                  <el-button
                    size="small"
                    @click="
                      scope._self.$refs[`popover-${scope.$index}`].doClose()
                    "
                    >取消</el-button
                  >
                  <el-button
                    type="primary"
                    size="small"
                    @click="handleConfirm(scope.$index, scope.row)"
                    >保存</el-button
                  >
                </div>
              </div>
              <div slot="reference" class="ref_text">
                {{ scope.row.price_range }}
                <i class="el-icon-edit"></i>
              </div>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <div class="sku_flex">
        <el-button size="medium" @click="handleClose" icon="el-icon-circle-close">取消</el-button>
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-success"
          @click="handleFirm"
          :loading="btnLoad"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
      loading: true, //加载
      gridData: [],
      sku: true, //是否显示

      input: "", //改价内容
      item: { goods_id: 0, item: [] },
      popovers: false,
      btnLoad: false, //保存加载
    };
  },
  created() {
    this.$axios.get("/api/Sku?goods_id=" + this.id).then((res) => {
      this.gridData = res.data.data;
      this.loading = false;
    }).catch(err => {
      console.log(err);
      this.loading = false;
    })
  },
  methods: {
    //   窗口关闭
    handleClose() {
      this.$emit("close", false);
    },
    // 关闭改价
    handleConfirm(index, row) {
      let data = {
        id: row.id,
        price_range: this.input,
      };
      this.item.goods_id = this.id;
      this.item.item.push(data);
      this.gridData[index].price_range = this.input;
      this.input = "";
      console.log(this.item);
      this.$refs[`popover-${index}`].doClose();
    },
    // 窗口关闭保存
    handleFirm() {
      if (this.item.item.length > 0) {
        this.btnLoad = true;
        this.$axios
          .put("/api/Sku", this.item)
          .then((res) => {
            this.btnLoad = false;
            setTimeout(() => {
              this.$emit("close", false);
            }, 500);
            if (res.data.status == 0) {
              this.$message({
                message: "保存成功",
                type: "success",
              });
            } else {
              this.$message({
                message: res.data.message,
                type: "error",
              });
            }
          })
          .catch((err) => {
            this.btnLoad = false;
            console.log(err);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.scope_box {
  display: flex;
  align-items: center;
  span {
    margin-left: 8px;
  }
}
.ref_text {
  cursor: pointer;
  .el-icon-edit {
    color: #3978f7;
  }
}
.scope_ref {
  width: 100%;
  text-align: center;
  p {
    font-size: 14px;
    color: #303133;
    font-weight: bold;
    margin-bottom: 18px;
  }
  .scope_btn {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
.sku_flex {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}
</style>
